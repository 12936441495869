import React, { Component } from 'react'
import posed from 'react-pose'
import { graphql, navigate } from 'gatsby'
import './_post.css'
import Img from 'gatsby-image'
import { View, Text, Page, SEO } from 'lib'
import { makeStyles } from '@material-ui/styles'
import Contact from 'pages/sections/_contact'

export default function Post({ data }) {
  const meta = data.markdownRemark.frontmatter
  const styles = useStyles()

  return (
    <Page fullWidth>
      <SEO title={meta.title} description={meta.subtitle} image={meta.image.childImageSharp.sizes.src}/>
      <View className={`${styles.postContent} ${styles.postMeta}`}>
        <View className={styles.backlinkWrapper}>
          <Animation onClick={() => navigate('/blog')}>
            <Text text={'← All posts'}/>
          </Animation>
        </View>
        <Text className={styles.titleText} text={meta.title}/>
      </View>
      {!meta.hideImageInPost && <Img sizes={meta.image.childImageSharp.sizes} className={styles.backgroundImage} alt={meta.title}/>}
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} className={styles.postContent}/>
      <Contact/>
    </Page>
  )
}

const Animation = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.1,
  },
})

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        subtitle
        title
        image {
          childImageSharp {
              sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
              }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  postMeta: {
    paddingTop: `${theme.spacing(4)}px !important`,
  },
  postContent: {
    fontFamily: theme.typography.specialFont,
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    lineHeight: 1.5,
  },
  backlinkWrapper: {
    display: 'flex',
    cursor: 'pointer',
  },
  titleText: {
    fontSize: 40,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  authorMetaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  profileImage: {
    marginRight: theme.spacing(1),
  },
  previewInfoText: {
    fontSize: 14,
    color: '#777',
  },
  backgroundImage: {
    width: '100vw',
    height: 400,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    objectFit: 'cover',
  },
}))
