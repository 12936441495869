import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { View } from 'lib'
import ContactForm from './_contactForm'
import Hidden from '@material-ui/core/Hidden'

export default function Contact(props) {
  const styles = useStyles(props)
  return (
    <View className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <ContactForm/>
      </div>
      <Hidden smDown>
        <div className={styles.rightWrapper}/>
      </Hidden>
    </View>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100vw',
    minHeight: 500,
    display: 'flex',
  },
  leftWrapper: {
    flex: 1,
    background: theme.palette.primary.main,
    ...theme.centralise,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  rightWrapper: {
    flex: 1,
    backgroundSize: 'cover',
    background: "url('/images/map.jpg') no-repeat center center",
    [theme.breakpoints.down('sm')]: {
      background: 'inherit',
    },
  },
}))
